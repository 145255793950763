@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

.container {
  padding-top: 10%;
  font-family: sans-serif;
  max-width: 93.75rem;
  margin: 0 auto;
  /* padding: 40px; */
  /* margin-bottom: 20px; */

}

.title {
  color: #229cff;
  font-family: 'Montserrat', sans-serif;
  font-size: 3rem;
  text-align: center;
}

.moreLink {
  color: rgb(253, 253, 253);
  font-size: 2rem;
  text-decoration: none;
  margin-top: 1em;
  display: inline-block;
  font-weight: bold;
  padding: 0.5em;
  margin-left: -0.5em;
  padding-bottom: 1rem;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);

  &:before,
  &:after {
    position: absolute;
    content: '';
    border-bottom: 3px solid rgb(255, 255, 255);
    border-radius: 1em;
    bottom: .3em;
    transition: transform .5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:before {
    width: 1em;
    transform-origin: left;
  }

  &:after {
    width: 82%;
    left: 1em;
    transform: translateX(110%);
  }

  &:hover:before {
    transform: scaleX(0.3);
  }

  &:hover:after {
    transform: translateX(0);
  }

}

/* grid-container, sử dụng grid layout để sắp xếp các bài viết */
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
  /* Tạo các cột tự động điều chỉnh, mỗi cột rộng tối thiểu 370px */
  grid-gap: 20px;
  /* Khoảng cách giữa các bài viết */
  width: 100%;
}

/* blog-post, định dạng từng bài viết */
.blog-post {
  border-radius: 8px;
  /* Bo góc */
  padding: 20px;
  /* Khoảng cách bên trong bài viết */

  /* Hiệu ứng đổ bóng nhẹ */
  transition: all 0.3s ease;
  /* Hiệu ứng chuyển đổi mượt mà khi hover */
  position: relative;
  /* Cho phép định vị tuyệt đối các phần tử con bên trong */
  overflow: hidden;
  /* Ẩn các phần tử tràn ra khỏi bài viết */
  background-color: rgba(255, 255, 255, 0.479);
  /* Màu nền trắng với độ trong suốt 0.7 */
  backdrop-filter: blur(15px);
  box-shadow: 0 5px 8px #6eaaff5a;
  border: 0.5px solid #6eaaff1f;
}


/* Hiệu ứng khi di chuột vào bài viết */
.blog-post:hover {
  transform: translateY(-5px);
  /* Di chuyển lên trên 5px */
  box-shadow: 0 5px 8px #6eaaff5a;
  /* Đổ bóng đậm hơn */
}

/* Hình ảnh trong bài viết */
.blog-post img {
  width: 100%;
  height: 12.5rem;
  object-fit: cover;
  /* Hình ảnh bao phủ toàn bộ vùng chứa, có thể bị cắt bớt để giữ tỉ lệ */
  border-radius: 8px;
  /* Bo góc hình ảnh */
  margin-bottom: 15px;
  /* Khoảng cách dưới hình ảnh */
  transition: transform 0.3s ease;
  /* Hiệu ứng chuyển đổi mượt mà khi hover */
}

/* Hiệu ứng phóng to hình ảnh khi di chuột vào bài viết */
.blog-post:hover img {
  transform: scale(1.05);
}

/* Tiêu đề bài viết */
.blog-post h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
  color: #333;
}

/* Nội dung bài viết */
.blog-post p {
  margin-bottom: 15px;
  line-height: 1.6;
  color: #555;
}

/* Ngày đăng bài viết */
.blog-post .date {
  font-size: 0.9em;
  color: #999;
  margin-top: 10px;
}

/* Thể loại bài viết */
.blog-post .category {
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  color: #333;
  font-size: 0.8em;
}

/* Nút "Đọc thêm" */
.blog-post .read-more {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-size: 1em;
  transition: background-color 0.3s ease;
}

/* Hiệu ứng khi di chuột vào nút "Đọc thêm" */
.blog-post .read-more:hover {
  background-color: #0069d9;
}

/* Thêm khoảng cách giữa các bài viết */
.grid-container>div {
  margin-bottom: 20px;
}

/* Điều chỉnh giao diện trên các kích thước màn hình khác nhau */

@media (max-width: 768px) {
  .container {
    max-width: 75rem;
    padding: 20px;
  }

  .title {
    font-size: 2rem;
  }

  .grid-container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    /* Điều chỉnh độ rộng tối thiểu của cột */
  }
}