.logo-container {
    flex-shrink: 0;
    max-width: 150px;
    /* Hoặc một giá trị phù hợp với thiết kế của bạn */
    height: auto;
}

.logo-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}