@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* Thanh điều hướng */
.navbar {
  background: linear-gradient(to bottom, rgba(44, 44, 44, 0.308), transparent);
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
  padding: 0.5rem 0;
  position: fixed;
  width: 100%;
  transition: top 0.3s ease-in-out;
  z-index: 9999;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 70rem;
  margin: 0 auto;
  padding: 0px 1rem;
}

.container img {
  width: 70px;
}

/* Danh sách liên kết điều hướng */
.nav-links {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.nav-links li {
  font-family: "Poppins", sans-serif;
  margin-left: 2rem;
  transition: transform 0.2s ease;
  font-size: 1.5rem;
  color: #4e9deb;
  text-decoration: none;
  transition: text-decoration 0.2s ease;
}

.nav-links li:hover {
  transform: translateY(-3px);
}

.nav-links li a,
.link {
  font-size: 1.5rem;
  color: #4e9deb;
  text-decoration: none;
  transition: text-decoration 0.2s ease;
}

.nav-links li:hover a {
  text-decoration: underline;
  color: #4e9deb
}

.article-link {
  cursor: pointer;
}

/* Nút menu (ẩn trên màn hình lớn) */
.menu-button {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
}

.bar {
  display: block;
  width: 25px;
  height: 3px;
  background-color: #ffffff;
  margin: 5px 0;
}

/* Hiệu ứng làm mờ dần */
@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Responsive cho menu di động */
@media (max-width: 768px) {
  .nav-links {
    text-align: center;
    width: 30%;
    /*  LOẠI BỎ display: none và display: flex; ở đây */
    flex-direction: column;
    /* Vẫn giữ flex-direction */
    /* position: fixed; */
    top: 70%;
    /* left: 75%; */
    transform: translateX(-8%);

  }

  .nav-links li {
    margin: 0.5rem 0;
  }

  .menu-button {
    display: block;
    margin-right: 10%;
    padding: 0.3rem 1rem;
    border-radius: 15px;
  }

  .menu-button:hover,
  .menu-button:active {
    background-color: rgba(0, 0, 0, 0.089);
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Logo kích thước nhỏ hơn trên màn hình rất nhỏ */
@media (max-width: 576px) {
  .container img {
    width: 48px;
  }

  .nav-links li a {
    font-size: 1.2rem;
  }
}