.customBackground {
    position: fixed;
    width: 100%;
    height: 100%;
    /* background-color: rgb(0, 0, 0); */
    /* background-image: url('https://firebasestorage.googleapis.com/v0/b/lanabassactravel-b476d.appspot.com/o/background%2FWhite%20Background.jpg?alt=media&token=4868cd62-11ee-4407-9f4d-2f985182e35chttps://firebasestorage.googleapis.com/v0/b/lanabassactravel-b476d.appspot.com/o/background%2F3840x2160-white-solid-color-background.jpg?alt=media&token=8139efb2-1c37-40df-a5f7-822b21c085bd'); */
    background-repeat: no-repeat;
    background-position: center top;
    /* background-attachment: fixed; */
    background-size: cover;
    z-index: -1;
}