/* Nhập font Montserrat từ Google Fonts */
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

/* Vùng chứa slideshow */
.slideshow-wrapper {
  position: relative;
  overflow: hidden;
  /* Ẩn phần tử tràn */
  padding-bottom: 2rem;
  width: 90%;
  margin: 0 auto;
  height: 100%;
  margin-top: -3%;
  border-radius: 15px;
  box-shadow: 0 5px 8px #6eaaff5a;
  /* background-color: rgba(236, 236, 236, 0.618); */
  border: 0.5px solid #6eaaff1f;
}

/* Slide đơn lẻ */
.slide {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 34rem;

}

.slide img {
  width: 95%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 15px;
  border: 0.5px solid #6eaaffa0;
}

/* Caption (chú thích) trên slide */
.caption {
  position: absolute;
  bottom: 15%;
  /* Vị trí caption cách đáy */
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: white;
  opacity: 0;
  /* Ẩn caption ban đầu */
  animation: fadeInUp 1s forwards;
  /* Hiệu ứng xuất hiện */
}

/* Tiêu đề chính trên slideshow */
.slideshow-wrapper h1 {
  color: #229cff;
  font-size: 3rem;
  font-family: 'Montserrat', sans-serif;
  text-align: center;
  margin-top: 2rem;
}

/* Tiêu đề và đoạn văn trong slide */
.slide h2,
.slide p {
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  /* Bóng đổ nhẹ */
}

.slide h2 {
  font-size: 2.7rem;
}

.slide p {
  font-size: 1.3rem;
}

/* Hiệu ứng làm mờ dần và di chuyển lên */
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate(-100%, 150%);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, 20%);
  }
}

/* Tiêu đề và nội dung caption */
.caption-title {
  font-size: 30px;
  font-family: 'Montserrat', sans-serif;
}

.caption-text {
  font-size: 15px;
  font-family: 'Montserrat', sans-serif;
}

/* Responsive cho màn hình nhỏ hơn 768px */
@media (max-width: 768px) {
  .slideshow-wrapper h1 {
    font-size: 33px;
  }

  .slide {
    max-height: 18.75rem;
    margin: 0px auto;
    align-items: center;
  }

  .slide img {
    /* object-fit: contain; */
    /* Ảnh nằm gọn trong khung */
    max-height: 77%;
    /* align-items: center; */
  }

  .slide h2 {
    font-size: 25px;
    margin-bottom: 1px;
  }

  .slide p {
    font-size: 13px;
    margin-top: 1px;
  }
}