.blog-container {
    max-width: 100%;
    /* margin: 15px 10px; */
    padding-top: 20px;

    @media (min-width: 768px) {
        max-height: 100%;
        border-radius: 25px;
        /* margin-top: 80px; */
        /* padding-bottom: 70px; */
    }
}