/* LoadingPage.module.css */
.loadingPage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 9999;
}



.logo {
    width: 6.25rem;
}