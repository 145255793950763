@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

.main-container {
    width: 99%;
    max-width: 100%;
    margin: 20px auto;
    background-color: rgba(236, 236, 236, 0.618);
    /* Màu nền trắng với độ trong suốt 0.7 */
    backdrop-filter: blur(15px);
    box-shadow: 0 5px 8px #6eaaff5a;
    border: 0.5px solid #6eaaff1f;
    /* Viền mỏng màu xám nhạt */
    border-radius: 8px;
    font-family: 'Montserrat', sans-serif;
}

.info-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    padding: 30px 0px 20px 0px;
    color: #696969;
}

.info-container h1 {
    text-align: left;
    font-size: 25px;
    color: rgb(44, 109, 248);
}

.info-icon {
    font-size: 1.2em;
    margin-right: 0.5em;
}

.section-1 h5 {
    font-size: 14px;
    font-weight: bold;
}

.section-1 h6 {
    font-size: 14px;
    font-weight: 550;
}

.section-1 p {
    font-size: 14px;
}

.section-2 span {
    font-size: 15px;
}

.svgbox {
    /* display: flex; */
    /* Đảm bảo SVG nằm trên cùng một hàng */
    justify-content: center;
    /* Căn giữa các SVG theo chiều ngang */
    align-items: center;
    /* Căn giữa các SVG theo chiều dọc */
}

.svgbox svg {
    /* width: 3rem; */
    margin: 20px 10px 0px 10px;
    transition: all 0.3s ease;

}

.info-container svg:hover {
    transform: translateY(-5px);
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

}

ul li {
    padding: 8px 0;
    text-decoration: none;
    /* Xóa bỏ gạch chân mặc định */
    transition: all 0.3s ease;
    font-size: 14px;
    color: #696969;
}

ul li:hover {
    text-decoration: underline;
    transform: translateY(-5px);
}



/* Responsive cho màn hình nhỏ (smartphone) */
@media (max-width: 768px) {
    .main-container {
        width: 94%;
    }

    .info-container {
        flex-direction: column;
        text-align: center;
        border-bottom: inset #6969694c;
    }

    .info-container h1 {
        text-align: center;
    }

    .section-3,
    .section-4 ul li {
        text-align: left;
    }
}