.Maincontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 99%;
    /* Đảm bảo maincontainer chiếm toàn bộ chiều rộng */
  
    /* Giới hạn chiều rộng tối đa nếu cần */
    margin: 0 auto;
    /* Căn giữa maincontainer theo chiều ngang */
    padding: 10px 0px;
    padding-bottom: 50px;

}

.Container {
    width: 100%;
    display: flex;
    justify-content: center;
    /* Căn giữa các phần tử con theo chiều ngang */
    align-items: center;
    /* Căn giữa các phần tử con theo chiều dọc */
    text-align: center;
    /* Căn giữa văn bản */
    padding: 8px 0;
    background-color: rgba(236, 236, 236, 0.618);
    /* Màu nền trắng với độ trong suốt 0.7 */
    backdrop-filter: blur(15px);
    box-shadow: 0 5px 8px #6eaaff5a;
    /* border: 0.5px solid #6eaaffa0; */
    /* Viền mỏng màu xám nhạt */
    border-radius: 8px;
}

.Logos img {
    max-width: 2.5rem;
    margin-top: 3px;
    margin-right: 10px;
    /* Khoảng cách giữa logo và văn bản */
}

.Container p {
    margin-top: 15px;
    padding: 0px 20px;
    color: rgb(131, 131, 131);
    /* Màu chữ xám */
    font-size: 13px;
    /* -webkit-text-stroke: 0.5px rgb(194, 194, 194); */
}



/* Responsive cho màn hình nhỏ (smartphone) */
@media (max-width: 768px) {
    .Maincontainer {
        /* padding: 0px 10px; */
        padding-bottom: 10px;

    }

    .Container {
        width: 95%;
        /* padding-left: 10px; */
        margin-bottom: 0px;
    }

    .Container p {
        padding: 0px 8px;
        color: rgb(131, 131, 131);
        /* Màu chữ xám */
        font-size: 13px;
        /* -webkit-text-stroke: 0.5px rgb(194, 194, 194); */
    }


    .Logos {
        margin-left: 10px;
    }
}