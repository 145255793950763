@import url('https://fonts.googleapis.com/css?family=Montserrat:400,800');

.container {
    font-family: sans-serif;
    max-width: 93.75rem;
    /* max-height: fit-content; */
    margin: 0 auto;
    /* padding: 20px; */
    padding-top: 10%;
}

.tourPrice {
    position: absolute;
    background: #047d4d;
    color: #fff;
    bottom: 15px;
    left: -11px;
    z-index: 9;
    padding: 2px 20px 25px 20px;
    height: 26px;
    line-height: 26px;
    border-radius: 10px 0px 10px 0px;
}

.tourPrice p {
    color: #fff;
}

.tourPrice:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border: 0 solid transparent;
    border-left-width: 12px;
    border-right-width: 0px;
    border-top: 13px solid #075f3c;
    position: absolute;
    left: 0;
    top: 27px;
}

.tourTitle {
    padding-bottom: 2rem;
    color: #333;
}

.tourTitleText {
    font-size: 1.6rem;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.tourRouteText {
    font-size: 1.2rem;
    color: #696969;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.tourDurationText {
    color: #d52c2c;
    font-size: 1.1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    position: absolute;
    right: 3%;
    bottom: 0;
}

.title {
    color: #229cff;
    font-family: 'Montserrat', sans-serif;
    font-size: 3rem;
    text-align: center;
}

.swiperContainer {
    position: relative;
    width: 100%;
    overflow: pre-wrap;
    padding-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    /* height: 25rem; */
}

.swiperSlide {
    width: 100%;
    overflow: pre-wrap;
    flex: 0 0 auto;
}

.cardBody {
    height: 12rem;
}

.tourcard {
    text-align: center;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 5px;
    transition: all 0.3s ease;
    backdrop-filter: blur(15px);
    box-shadow: 0 5px 8px #6eaaff5a;
    border: 0.5px solid #6eaaff1f;
    margin: auto 15px
}

.tourcard:hover {
    transform: translateY(5px);
    box-shadow: 0 4px 8px #6eaaff74;
}

.tourImage {
    width: 100%;
    height: 13.5rem;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 15px;
}

.tourPrice {
    text-align: left;
    font-size: 1.2em;
    color: #666;
}

@media (max-width: 768px) {
    .title {
        font-size: 2rem;
    }
}