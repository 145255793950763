/* button1.module.css */
@import url("https://fonts.googleapis.com/css?family=Mukta:700");

/* :root {
    --bg: #f3f8fa;
    --white: #fff;
    --black: #282936;
} */


.button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
    width: 15rem;
    height: auto;
}

.button .circle {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: relative;
    display: block;
    margin: 0;
    width: 4rem;
    height: 4rem;
    background: #6eaaffac;
    backdrop-filter: blur(5px);
    border-radius: 2rem;
}

.button .circle .icon {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background: black;
}

.button .circle .icon.arrow {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    left: 0.725rem;
    width: 1.125rem;
    height: 0.125rem;
    background: none;
}

.button .circle .icon.arrow::before {
    position: absolute;
    content: "";
    top: -0.30rem;
    right: 0.0225rem;
    left: 0.9rem;
    width: 0.625rem;
    height: 0.625rem;
    border-top: 0.125rem solid #000000;
    border-right: 0.125rem solid #000000;
    transform: rotate(45deg);

}


.button .button-text {
    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
    position: absolute;
    top: -19px;
    left: 5px;
    right: 0;
    bottom: 0;
    padding: 1.75rem 1rem;
    margin: 0 0 0 1.85rem;
    color: black;
    font-size: 26px;
    font-weight: 350;
    line-height: 1.6;
    text-align: center;
    text-transform: uppercase;
}

.button:hover .circle {
    width: 100%;
    background: #6eaaff;
    backdrop-filter: blur(5px);
}

.button:hover .circle .icon.arrow {
    transform: translate(1rem, 0);
}

.button:hover .button-text {
    color: rgb(255, 255, 255);
}