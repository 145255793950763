/* Import font giữ nguyên */
@import url('https://fonts.googleapis.com/css2?family=Bitter:ital,wght@0,801;1,801&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');

/* Phần hero */
.hero {
    text-align: center;
    overflow: hidden;
    height: 100vh;
    position: relative;
    padding-top: 3%;
    /* padding-bottom: 3%; */
    display: flex;
    justify-content: center;
}

.hero-content {
    z-index: 1;
}

.logo {
    width: 50%;
    max-width: 150px;
}

.box {
    width: 100%;
    max-width: 100vh;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 0 0 2rem 0;
}

.title {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    height: auto;
}

.title h1 {
    width: 100%;
    font-family: 'Poppins';
    color: #4e9deb;
    font-weight: 600;
    animation: mainFadeIn 2s forwards;
    animation-delay: 1.6s;
    opacity: 1;
    display: block;
    align-items: center;
    position: relative;
    text-align: center;
    font-size: 2rem;
}

.role p {
    font-size: 1.3rem;
}


.role {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    height: 30px;
    margin-top: -10px;
}

.role .block {
    width: 0%;
    height: 2.5rem;
    background: #e91e63;
    position: absolute;
    animation: secBlock 2s cubic-bezier(.74, .06, .4, .92) forwards;
    animation-delay: 2s;
    display: flex;
    margin-bottom: 10px;
}

.role p {
    width: 100%;
    animation: secFadeIn 2s forwards;
    animation-delay: 3.2s;
    opacity: 0;
    font-weight: 500;
    font-family: 'Lato';
    color: #000000;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 5px;
}

/* Các animation keyframes */
@keyframes mainBlock {
    0% {
        width: 0%;
        left: 0;
    }

    50% {
        width: 100%;
        left: 0;
    }

    100% {
        width: 0;
        left: 100%;
    }
}

@keyframes secBlock {
    0% {
        width: 0%;
        left: 0;
    }

    50% {
        width: 100%;
        left: 0;
    }

    100% {
        width: 0;
        left: 100%;
    }
}

@keyframes mainFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes secFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 0.5;
    }
}

@media (min-width: 768px) {

    .role p {
        font-size: 1.5rem;
    }

    .title h1 {
        font-size: 6.5rem;
    }

    .logo {
        width: 50%;
    }
}

@media (max-width: 768px) {
    .box {
        max-width: 550px;
    }

    .role p {
        font-size: 1rem;
    }

    .title h1 {
        font-size: 3.3rem;
        font-weight: 600;
    }

    .logo {
        width: 50%;
    }
}